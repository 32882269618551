import React, { useEffect, useState } from "react";
import { TagSection } from "@/src/component/base";
import { useApp } from "@/src/component/provider";
import { AppSearchFilterType } from "@/src/type";
import { AppSearchFilterOption } from "@/src/type/AppSearchFilterType";

interface Props extends AppSearchFilterType.AppSearchFilter {
  filtersStateRef: React.MutableRefObject<Record<string, string[]>>;
}

function FilterPanelSection({
  title,
  dataKey,
  options = [],
  startOpen = false,
  filtersStateRef,
}: Props) {
  const app = useApp();
  const MAX_VISIBLE_OPTIONS = 13;
  const globalTagPrefix = `${dataKey}-`;

  const [selectedTagValues, setSelectedTagValues] = useState<string[]>([]);

  const updateFilterState = (newValues: string[]) => {
    setSelectedTagValues(newValues);
    filtersStateRef.current = {
      ...filtersStateRef.current,
      [dataKey]: newValues
        .filter((value) => value.includes(dataKey))
        .map((value: string) => getLocalTagValue(value)),
    };
  };

  const getGlobalTagValue = (tagValue: string) => {
    return `${globalTagPrefix}${tagValue}`;
  };

  const getLocalTagValue = (tagValue: string) => {
    if (tagValue.startsWith(globalTagPrefix)) {
      return tagValue.substring(globalTagPrefix.length);
    }
    return tagValue;
  };

  const toggleTagSelection = (tag: AppSearchFilterOption) => {
    const globalTagValue = getGlobalTagValue(tag.value);
    const updatedValues = selectedTagValues.includes(globalTagValue)
      ? selectedTagValues.filter((val: string) => val !== globalTagValue)
      : [...selectedTagValues, globalTagValue];
    updateFilterState(updatedValues);
  };

  useEffect(() => {
    const urlFiltersForRef: Record<string, string[]> = {};
    let urlFiltersForState: string[] = [];
    const appliedTags: string[] = app.searchFilters?.getFilterData(dataKey) ?? [];
    if (appliedTags) {
      urlFiltersForRef[dataKey] = appliedTags;
      urlFiltersForState = [
        ...urlFiltersForState,
        ...appliedTags.map((tagValue: string) => `${dataKey}-${tagValue}`),
      ];
    }

    setSelectedTagValues(urlFiltersForState);
    filtersStateRef.current = { ...filtersStateRef.current, ...urlFiltersForRef };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TagSection
      sectionTitle={title}
      tags={options}
      isExpandable
      showMoreButton={options.length > MAX_VISIBLE_OPTIONS}
      startExpanded={startOpen}
      startShowAll={false}
      maxVisibleTags={MAX_VISIBLE_OPTIONS}
      checkIsSelectedTag={(tag: AppSearchFilterOption) =>
        selectedTagValues?.includes(getGlobalTagValue(tag.value))
      }
      handleTagClick={toggleTagSelection}
    />
  );
}

export default FilterPanelSection;
