import { Typography } from "@mui/material";
import Link from "next/link";
import { LineClampText } from "@/src/component/base";
import { useCMS } from "@/src/component/provider";
import { ImageLogic, SearchLogic } from "@/src/model";
import { BlaceV2Type, ComponentType } from "@/src/type";
import { ArrayHelper, ImageFromCDN } from "@/src/util";
import { CarouselImage, DetailSectionInfoBody } from "@src/component/partial";
import styles from "./VenueLinkedVendors.module.scss";

interface VenueLinkedVendorItemProps {
  vendor: BlaceV2Type.SearchType.Search;
}

function VenueLinkedVendorItem({ vendor }: VenueLinkedVendorItemProps) {
  const cms = useCMS();
  const images: ComponentType.Carousel.ImageType[] = ArrayHelper.arraySortByKey(vendor.images ?? [], "order").map(
    (img, i): ComponentType.Carousel.ImageType => ({
      url: img.url?.length
        ? img.url
        : ImageFromCDN.imageSizeAndQuality(
            ImageLogic.ensureImageExtension(img.imageHash || ""),
            80,
            800,
            true,
          ),
      id: img.imageHash ?? `${i}`,
      alt: `${vendor.title} Vendor Image ${i}`,
    }),
  );

  return (
    <Link
      href={`${cms.constructLink(`/vendor/${vendor.slug}`)}`}
      className={styles.hideLinkStyles}
      rel="noopener noreferrer"
      target="_blank"
    >
      <div className={styles.venueVendorContainer}>
        <div className={styles.venueVendorImages}>
          <CarouselImage
            images={images}
            autoPlay={true}
            imageClassName={styles.venueVendorImageInnerImg}
            navButtonsAlwaysVisible={false}
            imageCountNoIndicators={0}
          />
        </div>
        <div className={styles.venueVendorDetail}>
          <div className={styles.venueVendorDetailHeader}>
            <Typography
              variant="body1"
              color="grey.900"
              //textTransform="uppercase"
              fontWeight="900"
            >
              {vendor.title}
            </Typography>
            {typeof vendor.price.priceLevel === "number" && (
              <Typography variant="body1" color="grey.900">
                {SearchLogic.formatSearchPricing(vendor)}
              </Typography>
            )}
          </div>
          <Typography variant="h6" color="grey.700">
            {SearchLogic.translateCategories(vendor.categories)?.[0]}
          </Typography>
          <LineClampText
            lineHeight={23}
            linesToShowDesktop={2}
            linesToShowMobile={2}
            ComponentWithText={
              <DetailSectionInfoBody tag="h4">{vendor.description}</DetailSectionInfoBody>
            }
          />
        </div>
      </div>
    </Link>
  );
}

export default VenueLinkedVendorItem;
