import React, { ElementRef, useEffect, useMemo, useRef, useState } from "react";
import { Typography } from "@mui/material";
import cn from "classnames";
import { useRouter } from "next/router";
import { BaseFallbackPopup } from "@/src/component/base/BaseFallbackPopup";
import { DetailLayout } from "@/src/component/layout";
import {
  CreateInquiryFooter,
  DetailSectionInfo,
  DetailSectionInfoBody,
  DetailSectionSimilarCarousel,
  ImageGrid,
  InquiryForm,
  InquiryFormCard,
  LoadAfter,
  ShareDetails,
} from "@/src/component/partial";
import { ReachedLimitPopup } from "@/src/component/partial/ReachedLimitPopup";
import { useApp } from "@/src/component/provider";
import { NO_PRICE_TEXT, PRICE_STARTING_PRE_TEXT, VENUE_MAX_GUEST_COUNT } from "@/src/const";
import {
  useBreakPointDown,
  useInquiryData,
  useInquiryFormParams,
  useNextNavigation,
  useReachedLimitInquiry,
} from "@/src/hook";
import {
  ActivityLogic,
  AuthLogic,
  CMSLogic,
  SearchLogic,
  VenueLogic,
} from "@/src/model";
import { StrapiCMSAPI } from "@/src/service";
import {
  ComponentType,
  StrapiCMSType,
} from "@/src/type";
import { InquiryTypes, SearchType } from "@/src/type/blaceV2";
import { Log, StringHelper, uniqueId } from "@/src/util";
import styles from "./VenueDetail.module.scss";
import {
  VenueAbout,
  VenueAmenities,
  VenueBestUsedFor,
  VenueButtonBar,
  VenueCapacity,
  VenueLinkedVendors,
  VenueMap,
  VenuePrice,
  VenueQuickFacts,
  VenueRoom,
  VenueTabs,
  VenueThingsYouShouldKnow,
} from "./component";

interface VenueDetailProps {
  searchItem: SearchType.SearchItem;
  venueLinkedVendors: ComponentType.Listing.VenueWithLinkedVendors;
  similarVenues: SearchType.Search[];
}

function VenueDetail({ searchItem, venueLinkedVendors, similarVenues }: VenueDetailProps) {
  const scrollFromTop = 200;
  const nextNavigation = useNextNavigation();
  const isMobile = useBreakPointDown("md");
  const app = useApp();
  const [showTabsBg, setShowTabsBg] = useState<boolean>(false);
  const [detailTabs, setDetailTabs] = useState<string>("about");
  const [lowerZIndex, setLowerZIndex] = useState<boolean>(false);
  const [inquiryDrawerOpen, setInquiryDrawerOpen] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState(false);
  /* prettier-ignore */
  const [venueMarketingAsset, setVenueMarketingAsset] = useState<
  | StrapiCMSType.StrapiData<StrapiCMSType.VenueMarketingAssetType & StrapiCMSType.StrapiAttributes<StrapiCMSType.VenueMarketingAssetType>>
  | undefined
  >(undefined);
  const venueMapRef = useRef<ElementRef<typeof VenueMap>>(null);
  const inquiryFormData = useInquiryData();
  const inquiryFormParams = useInquiryFormParams({ selectedVenue: searchItem });
  const {
    isLimitReachedPopupVisible,
    handleDialogClose,
    setInquiryLimitReached,
  } = useReachedLimitInquiry();

  const displayingPrice = VenueLogic.formatVenuePricing(searchItem);

  const router = useRouter();
  const isInPreviewMode = !!router?.query?.p; // preview mode during Listing Management
  const isInactivePopupOpen = !isAdmin && !isInPreviewMode && !SearchLogic.isSearchActive(searchItem);

  const navigateToDiscoverVenues = () => {
    nextNavigation.router.push(
      CMSLogic.constructLink(`/discover?dataType=venue&searchId=${uniqueId()}`)
    );
  };

  /**
   * change the images to a usable type for the image grid
   */
  const images: ComponentType.Carousel.ImageType[] = useMemo(
    () => SearchLogic.formatCarouselImages(searchItem),
    [searchItem]
  );

  /**
   * handle the mui response from the tabs container
   *
   * @param event - NOT used
   * @param newValue - the tab that is being clicked
   */
  const handleDetailTabChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setDetailTabs(newValue);
    //todo: this effect needs more work on desktop

    //wait for tab panel to render then scroll to top of tab panel
    setTimeout(() => {
      const tabPanelOffsetTop =
        document.getElementById(`tabs-${newValue}`)?.offsetTop ?? 0;
      if (tabPanelOffsetTop > 0) {
        //80 px top minus 12px padding (on desktop add another 80px for header)
        window.scrollTo(0, tabPanelOffsetTop - (isMobile ? 68 : 148));
      }
    }, 150);
  };

  useEffect(() => {
    if (AuthLogic.hasAuthToken()) {
      const isAdmin = AuthLogic.getAuthCurrentUser()?.is_admin;

      setIsAdmin(!!isAdmin);
    }
  }, []);

  /**
   * Activate a full screen white background behind the tabs nav when this is sticky
   * only on desktop
   */
  useEffect(() => {
    function handleScroll() {
      const observer = document.getElementById("VenueTabs");
      if ((observer?.getBoundingClientRect()?.top ?? 9999) === 80) {
        if (!showTabsBg) {
          setShowTabsBg(true);
        }
      } else {
        if (showTabsBg) {
          setShowTabsBg(false);
        }
      }
    }

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [showTabsBg]);

  /**
   * slight delay turn z-index back on for detail tabs
   */
  useEffect(() => {
    if (!app.searchFocus) {
      setTimeout(() => {
        setLowerZIndex(false);
      }, 250);
    } else {
      setLowerZIndex(true);
    }
  }, [app.searchFocus]);

  /**
   * Activity Tracking of this page
   * Call the venue marketing assets
   */
  useEffect(() => {
    ActivityLogic.ga4Tracking("select_content", {});
    ActivityLogic.facebookTracking("track", "ViewContent");
    ActivityLogic.toActivityService({
      action: "venue",
      actionId: searchItem.slug,
      actionIdType: "venueSlug",
      locationInApp: "VenueDetail.ts",
    });

    (async () => {
      const venueMarketingAssetResponse =
        await StrapiCMSAPI.getVenueMarketingAsset(
          `filters[slug][$eq]=${searchItem.slug}`
        );
      if ((venueMarketingAssetResponse.response?.data ?? []).length > 0) {
        setVenueMarketingAsset(venueMarketingAssetResponse.response?.data[0]);
      }
    })();
  }, [searchItem]);

  /**
   * log the data in the console in debug mode
   */
  useEffect(() => {
    Log.logToConsoleDebug("VenueDetail.tsx", "Venue Details", [
      { searchItem, venueMarketingAsset },
    ]);
  }, [searchItem, venueMarketingAsset]);

  return (
    <>
      <DetailLayout
        inquiryDrawerOpen={inquiryDrawerOpen}
        setInquiryDrawerClose={() => setInquiryDrawerOpen(false)}
        ImageGrid={
          <ImageGrid
            images={images}
            OverlayComponent={
              <ShareDetails
                name={searchItem.title}
                id={`venue_v2_${searchItem.searchId}`}
              /> // or searchItem.data.id
            }
          />
        }
        LeftContainer={
          <>
            {/**Header with facts about the venue*/}
            <Typography variant="h1">{searchItem.title}</Typography>
            {isMobile && (
              <Typography variant="h5">
                {VenueLogic.getAddressForDisplay(searchItem, true)}
              </Typography>
            )}
            <InquiryFormCard
              isExclusive={searchItem.facts?.isExclusive}
              primaryContact={VenueLogic.getVenueContact(searchItem)}
              pricing={`${displayingPrice}`}
              setInquiryDrawerOpen={() => setInquiryDrawerOpen(true)}
            />
            <div id="InquiryCardObserver" />
            <VenueQuickFacts
              ceilingHeight={searchItem.dimensions?.ceilingHeight}
              maxHeadCount={searchItem.capacity?.max}
              numberOfFloors={searchItem.dimensions?.numberOfFloors}
              sqFootage={searchItem.dimensions?.sqFootage}
            />
            <VenueButtonBar
              venue={searchItem}
              venueMarketingAsset={venueMarketingAsset}
              openMapButton={() => {
                venueMapRef.current?.openMapFullScreen();
              }}
            />
            {/**Tabs*/}
            {showTabsBg && !isMobile && (
              <div className={styles.detailTabsContainerDesktopBg} />
            )}
            <div
              id="VenueTabs"
              className={cn(
                styles.detailTabsContainer,
                isMobile && lowerZIndex ? styles.detailsZIndexOff : undefined
              )}
            >
              <VenueTabs
                venue={searchItem}
                detailTabs={detailTabs}
                setInitialTab={setDetailTabs}
                handleDetailTabChange={handleDetailTabChange}
              />
            </div>
            {/**About tab section*/}
            <div
              style={{ display: detailTabs === "about" ? "block" : "none" }}
              className={styles.detailTab}
              id="tabs-about"
            >
              <VenueAbout venueDescription={searchItem.description} />
              <VenueCapacity venueCapacity={searchItem.capacity} />
              <LoadAfter type="scroll" scrollFromTop={scrollFromTop}>
                <VenueMap venue={searchItem} ref={venueMapRef} />
              </LoadAfter>
              <VenueAmenities venueAttributes={searchItem.attributes} />
              <LoadAfter type="scroll" scrollFromTop={scrollFromTop}>
                <VenuePrice venue={searchItem} />
                <VenueThingsYouShouldKnow
                  thingsYouShouldKnow={searchItem?.facts?.thingsYouShouldKnow}
                />
              </LoadAfter>
              {StringHelper.hasData(searchItem?.price?.cancellationPolicy) && (
                <DetailSectionInfo
                  title="Cancellation Policy"
                  Component={
                    <DetailSectionInfoBody tag="body1">
                      {searchItem?.price?.cancellationPolicy}
                    </DetailSectionInfoBody>
                  }
                />
              )}
              <VenueBestUsedFor venueAttributes={searchItem.attributes} />
            </div>
            {/**Rooms section*/}
            <div
              style={{ display: detailTabs === "rooms" ? "block" : "none" }}
              className={styles.detailTab}
              id="tabs-rooms"
            >
              <VenueRoom venue={searchItem} />
            </div>
            {/**Vendors section*/}
            <div
              style={{ display: detailTabs === "vendors" ? "block" : "none" }}
              className={styles.detailTab}
              id="tabs-vendors"
            >
              <VenueLinkedVendors
                vendors={venueLinkedVendors}
                isExclusive={searchItem?.facts?.isExclusive}
              />
            </div>
          </>
        }
        RightContainer={
          <InquiryForm
            isExclusive={searchItem?.facts?.isExclusive}
            primaryContact={VenueLogic.getVenueContact(searchItem)}
            pricing={`${displayingPrice}`}
            slug={searchItem.slug}
            altCatalogId={inquiryFormParams?.altCatalogId}
            catalogId={searchItem.catalogId}
            searchId={searchItem.searchId}
            inquiryType={InquiryTypes.Venue}
            maxGuestCount={searchItem.capacity?.max ?? VENUE_MAX_GUEST_COUNT}
            inquiryFormData={inquiryFormData}
            setInquiryLimitReached={setInquiryLimitReached}
            listing={searchItem}
          />
        }
        BottomContainer={
          <LoadAfter type="scroll" scrollFromTop={scrollFromTop}>
            <DetailSectionSimilarCarousel
              title="Similar Venues"
              slug={searchItem.slug}
              similarSearchItems={similarVenues}
            />
          </LoadAfter>
        }
        FooterContainer={
          <CreateInquiryFooter
            explainPriceText={
              displayingPrice !== NO_PRICE_TEXT ? PRICE_STARTING_PRE_TEXT : undefined
            }
            pricing={displayingPrice}
            setInquiryDrawerOpen={() => setInquiryDrawerOpen(true)}
          />
        }
        elementIdToTriggerFooter="InquiryCardObserver"
      />
      <ReachedLimitPopup
        isLimitReachedPopupVisible={isLimitReachedPopupVisible}
        handleDialogClose={handleDialogClose}
      />
      <BaseFallbackPopup
        isOpen={isInactivePopupOpen}
        title="Oops, this listing is no longer active"
        subTitle="Click below to discover more venues on BLACE"
        ctaText="Search venues"
        popupClasses={styles.inactiveFallbackPopup}
        handleCtaClick={navigateToDiscoverVenues}
      />
    </>
  );
}

export default VenueDetail;
