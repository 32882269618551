import React, { useEffect, useState } from "react";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import { useApp } from "@/src/component/provider";
import styles from "./ExclusiveSwitchControl.module.scss";

interface ExclusiveSwitchControlProps {
  filtersStateRef:  React.MutableRefObject<Record<string, string[]>>;
}

function ExclusiveSwitchControl({ filtersStateRef }: ExclusiveSwitchControlProps) {
  const [isExclusiveSelected, setIsExclusiveSelected] = useState(false);
  const app = useApp();

  const handleExclusiveChange = (exclusiveVal: boolean) => {
    filtersStateRef.current = {
      ...filtersStateRef.current,
      isExclusive: exclusiveVal ? [`${exclusiveVal}`] : [],
    };
    setIsExclusiveSelected(exclusiveVal);
  };

  useEffect(() => {
    const isExclusive = app.searchFilters?.getFilterData("isExclusive");
    setIsExclusiveSelected(!!isExclusive);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.searchFilters?.filterState]);
  
  return (
    <FormControlLabel
      control={
        <Switch
          checked={isExclusiveSelected}
          onChange={(e) => handleExclusiveChange(e.target.checked)}
          className={styles.switchExclusive}
          inputProps={{ "aria-label": "BLACE Exclusive switch" }}
        />
      }
      className={styles.exclusiveControl}
      label={
        <Typography component="p" className={styles.exclusiveTag}>
          BLACE Exclusive
        </Typography>
      }
    />
  );
}

export default ExclusiveSwitchControl;
