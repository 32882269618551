import { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { useRouter } from "next/router";
import { BaseLoader } from "@/src/component/base/BaseLoader";
import { PageLayoutContext } from "@/src/component/layout/PageLayout";
import { Map, resultsToCards } from "@/src/component/partial";
import { useApp, useCMS } from "@/src/component/provider";
import { CMSContextType } from "@/src/component/provider/CMS/CMSContext";
import { ExtraFiltersModal } from "@/src/component/view/Listing/component/ExtraFiltersModal";
import { LISTINGS_PAGE_URL_KEY, POPULAR_LISTINGS_COUNT } from "@/src/const";
import { DEFAULT_LOCATION } from "@/src/const/map";
import { useBreakPointDown, useNextNavigation } from "@/src/hook";
import { ActivityLogic, ListingLogic, SearchLogic } from "@/src/model";
import { BlaceV2API } from "@/src/service";
import { BlaceV2Type, ListingDisplayVariant } from "@/src/type";
import { LastSearchQuery, MobileListingModalState } from "@/src/type/component/Listing";
import { Log, URLHelper } from "@/src/util";
import type { MapLocationMarker } from "@/src/component/partial";
import type { AppContextType } from "@/src/component/provider";
import styles from "./Listing.module.scss";
import { ListingHeader } from "./component/ListingHeader";
import { ListingsList } from "./component/ListingsList";
import { SlideUpListingsPanel } from "./component/SlideUpListingsPanel";

interface ListingProps {
  initialSearch: BlaceV2Type.AzureSearchQueryType.Response<BlaceV2Type.SearchType.SearchItem>;
  initialGeoPoints: BlaceV2Type.AzureSearchQueryType.Response<BlaceV2Type.SearchType.SearchItem>;
  initialQuery: BlaceV2Type.AzureSearchQueryType.Request;
  initialPerPage: number;
  initialPage: number;
}

/**
 * handle putting the results on map as markers
 */
function resultsToCoordinates(
  cms: CMSContextType,
  results?: BlaceV2Type.SearchType.SearchItem[],
): MapLocationMarker[] {
  if (!results || !Array.isArray(results)) {
    return [];
  }

  const markers: MapLocationMarker[] = [];

  for (const result of results) {
    for (const location of result.locations ?? []) {
      if (typeof location.latitude === "number" && typeof location.longitude === "number") {
        markers.push({
          lat: location.latitude,
          lng: location.longitude,
          listingData: result,
          to: cms.constructLink(`/${result.dataType}/${result.slug}`),
          slug: result.slug,
        });
      }
    }
  }
  return markers;
}

function Listing({
  initialSearch,
  initialGeoPoints,
  initialQuery,
  initialPerPage,
  initialPage,
}: ListingProps) {
  const isMobile = useBreakPointDown("md");
  const app = useApp();
  const cms = useCMS();
  const filters = app?.searchFilters;
  const pageLayoutContext = PageLayoutContext.usePageLayoutContext();
  const nextNavigation = useNextNavigation();
  const router = useRouter();

  const listingType = SearchLogic.getSearchDataTypeFromApp(app);
  const defaultViewVariant =
    !isMobile && listingType === BlaceV2Type.SearchTypes.Venue
      ? ListingDisplayVariant.map
      : ListingDisplayVariant.full;
  const viewVariant = (router.query.viewVariant as ListingDisplayVariant) || defaultViewVariant;

  const [isLoading] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [popularListings, setPopularListings] = useState<BlaceV2Type.SearchType.Search[]>([]);
  const [hoveredListingSlug, setHoveredListingSlug] = useState<string | undefined>(undefined);
  const [lastSearchQuery, setLastSearchQuery] = useState<LastSearchQuery>({
    searchId: app.searchFilters?.searchId,
    searchTerm: app.searchTerm,
    resultsPerPage: initialPerPage ?? 20,
    pageNumber: initialPage,
    searchResults: initialSearch,
    query: initialQuery,
    geoPoints: initialGeoPoints,
    viewVariant,
    cards: resultsToCards({
      items: initialSearch.value,
      viewVariant,
      ads:
        (1 * 5) % 10 === 0
          ? [{ placement: 4 }, { placement: 11 }]
          : [{ placement: 6 }, { placement: 14 }],
      alwaysEager: false,
      onCardHover: (listingSlug?: string) => setHoveredListingSlug(listingSlug),
    }),
  });

  useEffect(() => {
    // do it only if no other filter(s) are present
    if (!filters?.filterState?.regions?.length && !filters?.filterState?.dataType?.length) {
      setUserLocationAsRegionsQuickFilter();
    }

    return () => {
      pageLayoutContext.setShowListingQuickFiltersInHeader(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ensure that map will be shown only for venues
  useEffect(() => {
    if (
      listingType !== BlaceV2Type.SearchTypes.Venue &&
      viewVariant === ListingDisplayVariant.map
    ) {
      nextNavigation.updateQueryStringBulk([
        { name: "dataType", value: listingType },
        { name: "viewVariant", value: "" }, // fallback to default value
      ]);
    }
  }, [nextNavigation, listingType, viewVariant]);

  useEffect(() => {
    // enables quick filters for mobile
    pageLayoutContext.setShowListingQuickFiltersInHeader(isMobile);
  }, [isMobile, pageLayoutContext]);

  useEffect(() => {
    const fetchPopularListings = async () => {
      const popularListingsData = await ListingLogic.getPopularListings(
        listingType,
        POPULAR_LISTINGS_COUNT,
        filters?.filterState.regions || undefined,
        URLHelper.urlGetParameter("searchSessionId") ?? initialQuery.sessionId,
      );
      setPopularListings(popularListingsData);
    };

    // the popular listings should be shown only when no Listings cards found
    if (!lastSearchQuery?.cards?.length) {
      fetchPopularListings();
    }
  }, [
    lastSearchQuery?.cards?.length,
    listingType,
    initialQuery.sessionId,
    filters?.filterState?.regions,
  ]);

  useEffect(() => {
    const params = new URLSearchParams(nextNavigation.searchParams.toString());
    const isPageNotChanged =
      parseInt(params.get(LISTINGS_PAGE_URL_KEY) ?? "1", 10) === lastSearchQuery.pageNumber;
    const isSearchTermEmpty =
      (app.searchTerm ?? "|none|") === (lastSearchQuery.searchTerm ?? "|none|") ||
      (app.searchTerm === "" && (lastSearchQuery.searchTerm ?? "") === "");

    if (
      isPageNotChanged &&
      isSearchTermEmpty &&
      app.searchFilters?.searchId === lastSearchQuery.searchId
    ) {
      return;
    }

    Log.logToConsoleDebug("Listing.tsx", "Search variables triggered query", [
      {
        pageNumber:
          parseInt(params.get(LISTINGS_PAGE_URL_KEY) ?? "1", 10) === lastSearchQuery.pageNumber,
        searchTerm:
          (app.searchTerm ?? "|none|") === (lastSearchQuery.searchTerm ?? "|none|") ||
          (app.searchTerm === "" && (lastSearchQuery.searchTerm ?? "") === ""),
        filters: app.searchFilters?.searchId === lastSearchQuery.searchId,
      },
      {
        oldPageNumber: lastSearchQuery.pageNumber,
        currentPageNumber: parseInt(params.get(LISTINGS_PAGE_URL_KEY) ?? "1", 10),
        oldSearchId: lastSearchQuery.searchId,
        currentSearchId: app.searchFilters?.searchId,
        oldSearchTerm: lastSearchQuery.searchTerm ?? "|none|",
        currentSearchTerm: app.searchTerm ?? "|none|",
      },
    ]);

    const fetchData = async () => {
      // TODO : enable it when the solution with Map will be prepared
      // setIsLoading(true);
      await runSearchQuery(
        lastSearchQuery,
        app,
        parseInt(params.get(LISTINGS_PAGE_URL_KEY) ?? "1", 10),
      );
      // setIsLoading(false);
    };

    fetchData();
  }, [app, nextNavigation.searchParams, lastSearchQuery]);

  /**
   * when in debug mode show the search result in console
   */
  useEffect(() => {
    Log.logToConsoleDebug("Listing.tsx", "Search change", [
      { lastSearchQuery, initialGeoPoints, initialQuery },
    ]);
  }, [lastSearchQuery, initialGeoPoints, initialQuery]);

  const pages = useMemo(
    () =>
      Math.ceil(
        (lastSearchQuery.searchResults?.["@odata.count"] ?? 0) / lastSearchQuery.resultsPerPage,
      ),
    [lastSearchQuery],
  );

  const resetFilters = useCallback(() => {
    app.searchFilters?.resetFilterData(listingType);
  }, [listingType]);

  const toggleViewVariant = () => {
    const displayVariant =
      viewVariant === ListingDisplayVariant.map
        ? ListingDisplayVariant.full
        : ListingDisplayVariant.map;
    nextNavigation.updateQueryString("viewVariant", displayVariant);
  };

  const setUserLocationAsRegionsQuickFilter = async () => {
    const userGeo = await ActivityLogic.getGeoLocation();

    if (userGeo && filters) {
      if (DEFAULT_LOCATION.LA.states.includes(userGeo?.state || "")) {
        app.searchFilters?.setFilterData("regions", BlaceV2Type.Regions.LA);
      }

      if (DEFAULT_LOCATION.NY.states.includes(userGeo?.state || "")) {
        app.searchFilters?.setFilterData("regions", BlaceV2Type.Regions.NY);
      }
    }
  };

  /**
   * Run a search query after something changes
   *
   * @param lastSearchQuery
   * @param app
   * @param newPageNumber
   */
  async function runSearchQuery(
    lastSearchQuery: LastSearchQuery,
    app: AppContextType,
    newPageNumber: number,
  ) {
    const from = newPageNumber ?? 1;
    const queryType = app.searchQueryType ?? ListingDisplayVariant.full;
    const query = SearchLogic.defaultQuery(lastSearchQuery.resultsPerPage);
    const filters = app?.searchFilters;

    query.search = SearchLogic.constructSearchTerm(app.searchQueryType, app.searchTerm);
    query.queryType = queryType;
    query.filter = SearchLogic.constructFilter(filters?.filterState);
    query.sessionId =
      URLHelper.urlGetParameter("searchSessionId") ?? initialQuery.sessionId ?? query.sessionId;

    //get the results for the page number
    const searchResponse = await BlaceV2API.SearchServiceV2.postSearchQuery({
      ...query,
      skip: from * lastSearchQuery.resultsPerPage - lastSearchQuery.resultsPerPage,
    });

    if (searchResponse.status !== 200 || typeof searchResponse.body === "undefined") {
      //TODO: add a toast for generic failures
      return;
    }

    //get the results for geo points without page restrictions
    const geoQuery = JSON.parse(JSON.stringify(query));
    geoQuery.select =
      "title, slug, dataType, locations, facts, categories, images, regions, capacity, price";
    geoQuery.top = 10000;
    //filter the map results to only venues
    geoQuery.filter = `${(geoQuery.filter ?? "").length > 0 ? `${geoQuery.filter} and ` : ""}search.in(dataType,'venue','|')`;
    const geoRespones = await BlaceV2API.SearchServiceV2.postSearchQuery(geoQuery);

    let geoPointsToShow = searchResponse.body.payload;
    if (searchResponse.status == 200 && geoRespones?.body?.payload) {
      geoPointsToShow = geoRespones?.body?.payload;
    }

    setLastSearchQuery({
      searchId: app.searchFilters?.searchId,
      searchTerm: app.searchTerm,
      resultsPerPage: lastSearchQuery.resultsPerPage,
      pageNumber: from,
      searchResults: searchResponse.body.payload,
      query: query,
      geoPoints: geoPointsToShow,
      viewVariant,
      cards: resultsToCards({
        items: searchResponse.body.payload.value,
        viewVariant,
        ads:
          (from * 5) % 10 === 0
            ? [{ placement: 4 }, { placement: 11 }]
            : [{ placement: 6 }, { placement: 14 }],
        alwaysEager: true,
        onCardHover: (listingSlug?: string) => setHoveredListingSlug(listingSlug),
      }),
    });

    window.scrollTo(0, 0);
  }

  const toggleExtraFilters = (isOpen: boolean) => {
    app.setShowExtraFilters(isOpen);
    app.setSearchFocus(isOpen);
  };


  return (
    <>
      <ListingHeader
        showMap={listingType === BlaceV2Type.SearchTypes.Venue}
        isMobileListingModalClosed={viewVariant === ListingDisplayVariant.map}
        onMapToggle={toggleViewVariant}
        viewVariant={viewVariant}
        listingType={listingType}
      />
      <ExtraFiltersModal onClose={() => toggleExtraFilters(false)} listingType={listingType} />
      <div className={cn(styles.listingContainer, viewVariant)}>
        {isLoading && (
          <div className="loader">
            <BaseLoader size={45} />
          </div>
        )}

        {!isMobile && !isLoading && (
          <>
            <ListingsList
              listings={lastSearchQuery.cards || []}
              popularListings={popularListings}
              onFiltersReset={resetFilters}
              listingType={listingType}
              currentPage={lastSearchQuery.pageNumber ?? 1}
              pages={pages}
              viewVariant={viewVariant}
            />
            {(!listingType || listingType === BlaceV2Type.SearchTypes.Venue) && (
              <div className={cn(styles.listingMap, viewVariant)}>
                <Map
                  city={app?.searchFilters?.filterState?.regions}
                  visible={true}
                  centerMarker={DEFAULT_LOCATION.NY}
                  searchId={lastSearchQuery.searchId}
                  hoveredSlug={hoveredListingSlug}
                  markers={resultsToCoordinates(
                    cms,
                    lastSearchQuery.geoPoints.value ?? lastSearchQuery.searchResults.value ?? [],
                  )}
                />
              </div>
            )}
          </>
        )}

        {isMobile && !isLoading && (
          <>
            {viewVariant === ListingDisplayVariant.map ? (
              <>
                <SlideUpListingsPanel
                  currentPage={lastSearchQuery.pageNumber ?? 1}
                  listings={lastSearchQuery.cards}
                  modalState={MobileListingModalState.CLOSED}
                  onToggleModalState={toggleViewVariant}
                  pages={pages}
                  viewVariant={viewVariant}
                  listingType={listingType}
                  onResetFilters={resetFilters}
                  title={ListingLogic.getListingsCountTitle(lastSearchQuery)}
                />
                <div className={cn(styles.listingMap, ListingDisplayVariant.map)}>
                  <Map
                    city={app?.searchFilters?.filterState?.regions}
                    visible={true}
                    centerMarker={DEFAULT_LOCATION.NY}
                    hoveredSlug={hoveredListingSlug}
                    markers={resultsToCoordinates(
                      cms,
                      lastSearchQuery.geoPoints.value ?? lastSearchQuery.searchResults.value ?? [],
                    )}
                  />
                </div>
              </>
            ) : (
              <ListingsList
                listings={lastSearchQuery.cards || []}
                popularListings={popularListings}
                onFiltersReset={resetFilters}
                listingType={listingType}
                currentPage={lastSearchQuery.pageNumber ?? 1}
                pages={pages}
                viewVariant={viewVariant}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Listing;
