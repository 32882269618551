import { useMemo } from "react";
import { ListingGrid, resultsToCards } from "@/src/component/partial";
import { ListingCardWrapper } from "@/src/component/view/InquiryComplete/component/ListingCardWrapper";
import { BlaceV2Type, ListingDisplayVariant } from "@/src/type";
import styles from "./ListingSection.module.scss";

interface ListingSectionProps {
  itemsList: BlaceV2Type.SearchType.Search[];
  inquiryId?: string;
  sendIquiryHandler: (dataType: string, slug: string) => void;
}

function ListingSection({
  itemsList,
  inquiryId,
  sendIquiryHandler,
}: ListingSectionProps) {
  const viewVariant = ListingDisplayVariant.full;
  const cards = resultsToCards({ items: itemsList, viewVariant, inquiryId });
  const upSellCards = useMemo(
    () =>
      cards.map(card => (
        <ListingCardWrapper
          sendIquiryHandler={sendIquiryHandler}
          card={card}
          key={card.key}
        >
          {card}
        </ListingCardWrapper>
      )),
    [cards]
  );

  return (
    <div className={styles.listingWrapper}>
      <ListingGrid
        variant={viewVariant}
        cards={upSellCards}
        isFromUpSell={true}
      />
    </div>
  );
}

export default ListingSection;
