import React, { useEffect, useRef } from "react";
import { Box, Divider, Typography } from "@mui/material";
import cn from "classnames";
import { BaseButton, BaseModal } from "@/src/component/base";
import { FilterPanelSectionV2 } from "@/src/component/partial/FilterPanelSectionV2";
import { useApp } from "@/src/component/provider";
import { ExclusiveSwitchControl } from "@/src/component/view/Listing/component/ExclusiveSwitchControl";
import { VENDOR_LISTINGS_EXTRA_FILTERS, VENUE_LISTINGS_EXTRA_FILTERS } from "@/src/const";
import { SearchLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import styles from "./ExtraFiltersModal.module.scss";

interface ExtraFiltersModalProps {
  onClose: () => void;
  listingType: BlaceV2Type.SearchTypes;
}

function ExtraFiltersModal({ onClose, listingType }: ExtraFiltersModalProps) {
  const app = useApp();
  const isModalOpen = app.showExtraFilters;

  const filtersStateRef = useRef<Record<string, string[]>>({});

  const filterSections =
    listingType === BlaceV2Type.SearchTypes.Venue
      ? VENUE_LISTINGS_EXTRA_FILTERS
      : VENDOR_LISTINGS_EXTRA_FILTERS;

  const handleApplyFilters = () => {
    const updatedFilters = { ...filtersStateRef.current };
    app.searchFilters?.setMultiChoiceV2(updatedFilters);
    SearchLogic.trackFilterUsed(
      JSON.stringify(updatedFilters),
      "extraFiltersModal",
      "ExtraFiltersModal.tsx",
    );
    onClose();
  };

  const clearFilters = (filters: string[]) => {
    const clearedFilters = filters.reduce((filter, value) => ({ ...filter, [value]: [] }), {});
    app.searchFilters?.setMultiChoiceV2(clearedFilters);
    // the empty object was used instead of `clearedFilters` to have an ability to find the clear logic
    SearchLogic.trackFilterUsed(JSON.stringify({}), "extraFiltersModal", "ExtraFiltersModal.tsx");
    filtersStateRef.current = {};
  };

  const handleClearAll = () => {
    clearFilters(filterSections);
    onClose();
  };

  useEffect(() => {
    if (listingType) {
      filtersStateRef.current = {};
    }
  }, [listingType]);

  return (
    <BaseModal
      dialogProps={{ open: isModalOpen, onClose }}
      onExit={onClose}
      additionalClassName={styles.extraFiltersModal}
    >
      <Typography className={styles.modalTitle} data-testid="extra-filters-modal-title">
        Filters
      </Typography>
      <Divider className={styles.titleDivider} />
      <Box className={styles.modalContent}>
        {listingType === BlaceV2Type.SearchTypes.Venue && (
          <ExclusiveSwitchControl filtersStateRef={filtersStateRef} />
        )}
        {filterSections.map(
          (filter) =>
            !SearchLogic.FILTERS[filter]?.noTags && (
              <FilterPanelSectionV2
                key={SearchLogic.FILTERS[filter]?.dataKey}
                {...SearchLogic.FILTERS[filter]}
                filtersStateRef={filtersStateRef}
              />
            ),
        )}
      </Box>
      <Box className={styles.actionButtons}>
        <BaseButton
          className={cn(styles.actionButton, styles.clear)}
          onClick={handleClearAll}
          data-testid="clear-filters-btn"
        >
          Clear all
        </BaseButton>
        <BaseButton
          className={cn(styles.actionButton, styles.apply)}
          onClick={handleApplyFilters}
          data-testid="apply-filters-btn"
        >
          Apply filters
        </BaseButton>
      </Box>
    </BaseModal>
  );
}

export default ExtraFiltersModal;
